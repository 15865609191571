<template>
  <div class="text-center mt-5">
    <p>Sorry!! The requested content is not available for you.</p>
    <router-link to="/">Go to Home!</router-link>
  </div>
</template>

<script>
export default {
  name: 'NoAccess'
}
</script>

<style scoped>

</style>
